import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
export type RequestForgotPasswordDTO = {
  email: string;
};
export const updatePassword = (payload: RequestForgotPasswordDTO) => {
  return axios.post(`/auth/forgot_password`, payload);
};

type UseRequestForgotPasswordOptions = {
  config?: MutationConfig<typeof updatePassword>;
};

export const useRequestForgotPassword = ({ config }: UseRequestForgotPasswordOptions = {}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: t('MESSAGE.COMMON.FORGOT_SUCCESS_MESSAGE'),
      });
    },
    ...config,
    mutationFn: updatePassword,
  });
};
