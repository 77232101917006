import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const ScrollToTop = () => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState<string>();
  useEffect(() => {
    const { pathname } = location;
    setCurrentLocation(pathname);
    if (currentLocation !== pathname) {
      const divElement: any = document.getElementById('top-pages');
      divElement?.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' });
      return;
    }
    // eslint-disable-next-line
  }, [location]);

  return <></>;
};
