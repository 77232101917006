import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useNotificationStore } from '@/stores/notifications';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Layout title={t('COMMON.SIGN_UP')}>
      <RegisterForm
        onSuccess={() => {
          addNotification({
            type: 'success',
            title: t('MESSAGE.COMMON.REGISTER_SUCCESS_TITLE'),
            message: t('MESSAGE.COMMON.REGISTER_SUCCESS_MESSAGE'),
          });
          navigate('/auth/login');
        }}
      />
    </Layout>
  );
};
