const storagePrefix = 'travel_with_dog_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setRefreshToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}refresh_token`, JSON.stringify(token));
  },
  getRefreshToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}refresh_token`) as string);
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}refresh_token`);
  },
};

export default storage;
