import { Helmet } from 'react-helmet-async';

type HeadProps = {
  title?: string;
  description?: string;
  ogImage?: string;
  ogUrl?: string;
};

export const Head = ({
  title = '',
  description = '',
  ogImage = '',
  ogUrl = '',
}: HeadProps = {}) => {
  return (
    <Helmet title={title ? `${title} | Travel With Dog` : ''} defaultTitle="Travel With Dog">
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="" />
      <meta property="twitter:url" content={ogUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
    </Helmet>
  );
};
