import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
// import { NotFound } from '@/features/misc';
const { AuthRoutes } = lazyImport(() => import('@/features/auth'), 'AuthRoutes');
const Auth = () => {
  return (
    <MainLayout authLayout={true} showInstagram={false}>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <AuthRoutes />
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
export const publicRoutes = [
  {
    path: '/auth/*',
    element: <Auth />,
  },
];
