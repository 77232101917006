import { axios } from '@/lib/axios';

import { TokenInfoResponse } from '../types';

export type TokenInfoDTO = {
  token: string;
  type: string;
};

export const getTokenInfo = (data: TokenInfoDTO): Promise<TokenInfoResponse> => {
  return axios.post('/auth/token/info', data);
};
