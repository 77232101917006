import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { CreateContactDTO } from '../Types';
export const updatePassword = (payload: CreateContactDTO) => {
  return axios.post(`/contact/create`, payload);
};

type UseCreateContactOptions = {
  config?: MutationConfig<typeof updatePassword>;
};

export const useCreateContact = ({ config }: UseCreateContactOptions = {}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: t('MESSAGE.COMMON.CREATE_SUCCESS'),
      });
    },
    ...config,
    mutationFn: updatePassword,
  });
};
