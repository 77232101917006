import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import './style.scss';
import { getURLImage } from '@/utils/function';

import { useSns } from '../Api/getSns';

const Footer = () => {
  const { t } = useTranslation();
  const { data } = useSns();
  return (
    <div>
      <div className="flex justify-center pt-4">
        <Link className="flex items-center text-white" to="/">
          <img loading="lazy" className="h-12 w-auto" src={logo} alt="Workflow" />
        </Link>
      </div>
      <div className="flex justify-center divide-x divide-solid pt-4">
        <div className=" text-footer">{t('COMMON.APP_NAME')}について</div>
        <div className="px-2 text-footer">{t('COMMON.FOOTER.TITLE_1')}</div>
        <div className="px-2 text-footer">{t('COMMON.FOOTER.TITLE_2')}</div>
        <div className="px-2 text-footer">{t('COMMON.FOOTER.TITLE_3')}</div>
        <div className="px-2 text-footer">{t('COMMON.FOOTER.TITLE_4')}</div>
        <div className="px-2 text-footer">{t('COMMON.FOOTER.TITLE_5')}</div>
      </div>
      <div className="flex justify-center space-x-6 pt-4">
        {data?.length ? (
          <>
            {data.map((item, index) => (
              <Link
                target="_blank"
                to={item.redirect_url}
                className="w-12 h-12 social-logo rounded-full flex justify-center items-center"
                key={index}
              >
                <img
                  loading="lazy"
                  className="w-8 h-8"
                  src={getURLImage(item?.image_path)}
                  alt={item.name}
                />
              </Link>
            ))}
          </>
        ) : null}
      </div>
      <div className="flex justify-center text-footer py-4">
        Copyright© {t('COMMON.APP_NAME')}®. All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
