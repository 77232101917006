import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { useRequestForgotPassword } from '../api/requestFogotPassword';

type RegisterValues = {
  email: string;
};

type ForgotPasswordFormProps = {
  onSuccess: () => void;
};

export const RequestForgotPasswordForm = ({ onSuccess }: ForgotPasswordFormProps) => {
  const { mutateAsync, isLoading, isSuccess } = useRequestForgotPassword();
  const { t } = useTranslation();

  const schema = z.object({
    email: z.string().min(1, t('COMMON.REQUIRED')),
  });

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
    // eslint-disable-next-line
  }, [isSuccess]);
  return (
    <div>
      <Form<RegisterValues, typeof schema>
        onSubmit={async (values) => {
          await mutateAsync(values);
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('COMMON.EMAIL')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                className="w-full bg-red-100"
              >
                {t('COMMON.BUTTON.CHANGE_PASSWORD')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <span>
            {t('COMMON.ACCOUNT.HAVE')}
            <span className="pl-2">
              <Link to="../login" className="font-medium text-red-100 hover:text-red-100">
                {t('COMMON.SIGN_IN')}
              </Link>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
