import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
export type ForgotPasswordDTO = {
  token: string;
  password: string;
  password_confirmation: string;
};
export const updatePassword = (payload: ForgotPasswordDTO) => {
  return axios.post(`/auth/reset_password`, payload);
};

type UseForgotPasswordOptions = {
  config?: MutationConfig<typeof updatePassword>;
};

export const useForgotPassword = ({ config }: UseForgotPasswordOptions = {}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: t('MESSAGE.COMMON.FORGOT_SUCCESS'),
      });
    },
    ...config,
    mutationFn: updatePassword,
  });
};
