import { CLIENT_ID, CLIENT_SECRET } from '@/config';
import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
export type LoginCredentialsDTO = {
  username: string;
  password: string;
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/auth/login', {
    ...data,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'password',
  });
};
