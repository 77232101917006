import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { ForgotPasswordDTO, useForgotPassword } from '../api/fogotPassword';
import { getTokenInfo } from '../api/getTokenInfo';

type ForgotPasswordFormProps = {
  onSuccess: () => void;
};

export const ForgotPasswordForm = ({ onSuccess }: ForgotPasswordFormProps) => {
  const { mutateAsync, isLoading, isSuccess } = useForgotPassword();
  const [email, setEmail] = useState<string>('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  useEffect(() => {
    if (token) {
      getInfo(token);
    }
  }, [token]);
  const getInfo = async (token: string) => {
    const response = await getTokenInfo({
      type: 'sign_up',
      token: token,
    });
    const { email } = response;
    setEmail(email);
  };
  const schema = z.object({
    password: z.string().min(8, t('COMMON.PASSWORD.REQUIRED')),
    password_confirmation: z.string().min(8, t('COMMON.PASSWORD.REQUIRED')),
  });

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
    // eslint-disable-next-line
  }, [isSuccess]);
  return (
    <div>
      <Form<ForgotPasswordDTO, typeof schema>
        onSubmit={async (values) => {
          await mutateAsync({ ...values, token: token || '' });
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('COMMON.EMAIL')}
              value={email}
              disabled={true}
              placeholder={t('COMMON.EMAIL')}
            />

            <InputField
              type="password"
              label={t('COMMON.NEW_PASSWORD')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label={t('COMMON.CONFIRM_NEW_PASSWORD')}
              error={formState.errors['password_confirmation']}
              registration={register('password_confirmation')}
            />
            <div>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                className="w-full bg-red-100"
              >
                {t('COMMON.BUTTON.CHANGE_PASSWORD')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <span>
            {t('COMMON.ACCOUNT.HAVE')}
            <span className="pl-2">
              <Link to="../login" className="font-medium text-red-100 hover:text-red-100">
                {t('COMMON.SIGN_IN')}
              </Link>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
