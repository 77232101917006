import { CLIENT_SECRET, CLIENT_ID } from '@/config';

import { axiosRefreshToken } from './axiosRefreshToken';
const useRefreshToken = async (refresh_token_old: string) => {
  const payload = {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    refresh_token: refresh_token_old,
  };
  const response = await axiosRefreshToken.post(`/auth/refresh`, payload);
  if (response.status === 200) {
    return response.data;
  }
  return response;
};

export default useRefreshToken;
