import * as React from 'react';

import { Head } from '../Head';

type ContentLayoutProfileProps = {
  children: React.ReactNode;
  title: string;
};

export const ContentLayoutProfile = ({ children, title }: ContentLayoutProfileProps) => {
  return (
    <>
      <Head title={title} />
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex justify-center">
          <h1 className="text-2xl font-semibold text-red-100">{title}</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{children}</div>
      </div>
    </>
  );
};
