import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Form, InputField, TextAreaField } from '@/components/Form';

import { useCreateContact } from '../Api/createContact';
import { Button } from '../Elements/Button';
import { CreateContactDTO } from '../Types';
export const ContactForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const useCreateContactMutation = useCreateContact();
  const schema = z.object({
    email: z.string().min(1, t('COMMON.REQUIRED')),
    content: z.string().min(8, t('COMMON.PASSWORD.REQUIRED')),
  });
  useEffect(() => {
    if (useCreateContactMutation.isSuccess) {
      return navigate('/');
    }
    // eslint-disable-next-line
  }, [useCreateContactMutation.isSuccess]);
  return (
    <div className="flex justify-center">
      <div className="p-4 rounded-lg max-w-lg w-full">
        <div className="flex justify-center items-center py-4 text-red-100 font-bold text-xl">
          {t('COMMON.TITLE.CONTACT')}
        </div>
        <Form<CreateContactDTO, typeof schema>
          id="update-password"
          onSubmit={async (values) => {
            await useCreateContactMutation.mutateAsync(values);
          }}
          schema={schema}
          isSuccess={useCreateContactMutation?.isSuccess}
        >
          {({ register, formState }) => (
            <>
              <InputField
                type="email"
                label={t('COMMON.EMAIL')}
                error={formState.errors['email']}
                registration={register('email')}
              />
              <TextAreaField
                className="h-[200px]"
                label={t('COMMON.TITLE.CONTACT_MESSAGE')}
                error={formState.errors['content']}
                registration={register('content')}
              />

              <div>
                <Button
                  isLoading={useCreateContactMutation.isLoading}
                  disabled={useCreateContactMutation.isLoading}
                  variant="base"
                  type="submit"
                  className="w-full"
                >
                  {t('COMMON.BUTTON.CONTACT')}
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};
