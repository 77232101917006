import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { ActivateCredentialsDTO } from '../types';
export type ActivateAccountDTO = {
  token: string;
  password: string;
  password_confirmation: string;
};
export const activateAccount = (payload: ActivateCredentialsDTO) => {
  return axios.post(`/auth/activate`, payload);
};

type UseActivateAccountOptions = {
  config?: MutationConfig<typeof activateAccount>;
};

export const useActivateAccount = ({ config }: UseActivateAccountOptions = {}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: t('MESSAGE.COMMON.REGISTER_SUCCESS_TITLE'),
      });
    },
    ...config,
    mutationFn: activateAccount,
  });
};
