import { ContactForm } from './ContactForm';
import Footer from './Footer';
import { Instagram } from './Instagram';
import './style.scss';
type FooterGroupProps = {
  showInstagram?: boolean;
};
export const FooterGroup = ({ showInstagram }: FooterGroupProps) => {
  return (
    <div className="py-8 px-8 md:px-20 lg:px-40 bg-[] footer">
      {showInstagram && <Instagram />}
      {showInstagram && <ContactForm />}
      <Footer />
    </div>
  );
};
