import { useRoutes } from 'react-router-dom';

// import { Landing, NotFound } from '@/features/misc';
// import { useAuth } from '@/lib/auth';

import { BookingRoute } from './booking';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  // const auth = useAuth();

  // const commonRoutes = [
  //   {
  //     path: '/',
  //     element: <Landing />,
  //   },
  //   {
  //     path: '*',
  //     element: <NotFound />,
  //   },
  // ];

  // const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...protectedRoutes, ...publicRoutes, ...BookingRoute]);

  return <>{element}</>;
};
