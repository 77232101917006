import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { NotFound } from '@/features/misc';
import { lazyImport } from '@/utils/lazyImport';

// const { DiscussionsRoutes } = lazyImport(
//   () => import('@/features/discussions'),
//   'DiscussionsRoutes'
// );
// const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
// const { Users } = lazyImport(() => import('@/features/users'), 'Users');

const { HomepageRoutes } = lazyImport(() => import('@/features/homepage'), 'HomepageRoutes');
const { FavoriteRoutes } = lazyImport(() => import('@/features/favorite'), 'FavoriteRoutes');
const { SearchRoutes } = lazyImport(() => import('@/features/search'), 'SearchRoutes');
const { HotelDetailRoutes } = lazyImport(
  () => import('@/features/HotelDetail'),
  'HotelDetailRoutes'
);

const App = () => {
  return (
    <MainLayout authLayout={false} showInstagram={true}>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <HomepageRoutes /> },
      { path: '/favorite', element: <FavoriteRoutes /> },
      { path: '/hotel/*', element: <HotelDetailRoutes /> },
      { path: '/search/*', element: <SearchRoutes /> },
      { path: '/profile', element: <Profile /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];
