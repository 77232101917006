import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { Layout } from '../components/Layout';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Layout title={t('COMMON.TITLE.FORGOT_PASSWORD')}>
      <ForgotPasswordForm
        onSuccess={() => {
          navigate('/auth/login');
        }}
      />
    </Layout>
  );
};
