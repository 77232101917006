import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
// import { NotFound } from '@/features/misc';
const { BookingRoutes } = lazyImport(() => import('@/features/booking'), 'BookingRoutes');
const Booking = () => {
  return (
    <MainLayout authLayout={false} showInstagram={false}>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <BookingRoutes />
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
export const BookingRoute = [
  {
    path: '/booking/*',
    element: <Booking />,
  },
];
