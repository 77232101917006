import * as React from 'react';

import backgroundImage from '@/assets/bg-auth.png';
// import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div
        className="bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <div className="flex justify-center">
            <Link className="flex items-center text-white" to="/">
              <img className="h-24 w-auto" src={logo} alt="Workflow" />
            </Link>
          </div> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md min-w-[35vw]">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="py-3 text-center text-3xl font-extrabold text-red-100">{title}</h2>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
