import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

import useRefreshToken from './useRefreshToken';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const prevRequest = error?.config;
    const { response } = error;
    if (response) {
      const { status } = response;
      const refresh_token_old = await storage.getRefreshToken();
      if (status === 401 && refresh_token_old) {
        prevRequest.sent = true;
        return new Promise((resolve, reject) => {
          useRefreshToken(refresh_token_old)
            .then(async (res) => {
              const { access_token, refresh_token } = res;
              if (refresh_token && access_token) {
                await storage.setToken(access_token);
                await storage.setRefreshToken(refresh_token);
                prevRequest.headers['Authorization'] = `Bearer ${access_token}`;
                axios
                  .request(prevRequest)
                  .then((result) => {
                    return resolve(result);
                  })
                  .catch(async (err) => {
                    return reject(err);
                  });
              } else {
                storage.clearToken();
                // window.location.assign(window.location.origin as unknown as string);
              }
            })
            .catch(() => {
              storage.clearToken();
              // window.location.assign(window.location.origin as unknown as string);
            });
        });
      }
    }
    const message = error.response?.data?.message || error.message;
    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Error',
      message,
    });

    return Promise.reject(error);
  }
);
