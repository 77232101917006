import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useDisclosure } from '@/hooks/useDisclosure';

import { Button } from '../Elements/Button';
import { Drawer, DrawerProps } from '../Elements/Drawer';

type FormDrawerProps = {
  isDone?: boolean;
  triggerButton: React.ReactElement;
  submitButton?: React.ReactElement;
  title: string;
  children: React.ReactNode;
  size?: DrawerProps['size'];
  onClose?: () => void;
};

export const FormDrawer = ({
  title,
  children,
  isDone,
  triggerButton,
  submitButton,
  size = 'md',
  onClose,
}: FormDrawerProps) => {
  const { t } = useTranslation();
  const { close, open, isOpen } = useDisclosure();

  React.useEffect(() => {
    if (isDone) {
      close();
      onClose && onClose();
    }
  }, [isDone, close, onClose]);

  return (
    <>
      {React.cloneElement(triggerButton, { onClick: open })}
      <Drawer
        isOpen={isOpen}
        onClose={() => {
          close();
          onClose && onClose();
        }}
        title={title}
        size={size}
        renderFooter={() => (
          <>
            <Button
              variant="inverse"
              size="sm"
              onClick={() => {
                close();
                onClose && onClose();
              }}
            >
              {t('COMMON.BUTTON.CANCEL')}
            </Button>
            {submitButton}
          </>
        )}
      >
        {children}
      </Drawer>
    </>
  );
};
