import { CLIENT_ID, CLIENT_SECRET } from '@/config';
import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
export type RefreshTokenDTO = {
  refresh_token: string;
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
};

export const refreshTokenAPI = (data: RefreshTokenDTO): Promise<UserResponse> => {
  return axios.post('/auth/refresh', {
    ...data,
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  });
};
