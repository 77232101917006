import { Button } from 'antd';

import instagramColor from '@/assets/instagram_color.svg';
import location01 from '@/assets/location01.webp';
import phone from '@/assets/phone.png';

export const Instagram = () => {
  return (
    <div className="flex flex-col items-center">
      <div>@instagram_name</div>
      <div className="pt-2">
        <Button className="bg-white rounded-full text-red-100 h-full">
          <div className="flex space-x-2 h-full py-1">
            <img loading="lazy" src={instagramColor} alt="" />
            <div>Instagramをフォローする</div>
          </div>
        </Button>
      </div>
      <div className="flex w-full justify-center items-center space-x-2 py-8">
        <div className="flex-1">
          <img loading="lazy" src={location01} alt="" />
        </div>
        <div className="flex-1">
          <img loading="lazy" src={location01} alt="" />
        </div>
        <div className="flex-1">
          <img loading="lazy" src={phone} alt="" />
        </div>
        <div className="flex-1">
          <img loading="lazy" src={location01} alt="" />
        </div>
        <div className="flex-1">
          <img loading="lazy" src={location01} alt="" />
        </div>
      </div>
    </div>
  );
};
