import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Layout title={t('COMMON.SIGN_IN')}>
      <LoginForm onSuccess={() => navigate('/')} />
    </Layout>
  );
};
