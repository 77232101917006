import {
  //  Dialog,
  Menu,
  Transition,
} from '@headlessui/react';
// import {
//   // UserIcon,
//   // FolderIcon,
//   // HomeIcon,
//   // MenuAlt2Icon,
//   // UsersIcon,
//   // XIcon,
// } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  //  NavLink,
  Link,
} from 'react-router-dom';

import favorite from '@/assets/favorite.svg';
import login from '@/assets/login.svg';
import logo from '@/assets/logo.svg';
import profile from '@/assets/profile.svg';
import { useAuth } from '@/lib/auth';

import '../style.scss';
import { FooterGroup } from '../Footer';

import Breadcrumb from './BreadCrumb';

// import { useAuthorization, ROLES } from '@/lib/authorization';

// type SideNavigationItem = {
//   name: string;
//   to: string;
//   icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
// };

// const SideNavigation = () => {
//   // const { checkAccess } = useAuthorization();
//   const navigation = [
//     { name: 'Dashboard', to: '.', icon: HomeIcon },
//     { name: 'Discussions', to: './discussions', icon: FolderIcon },
//     // checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
//     //   name: 'Users',
//     //   to: './users',
//     //   icon: UsersIcon,
//     // },
//   ].filter(Boolean) as SideNavigationItem[];

//   return (
//     <>
//       {navigation.map((item, index) => (
//         <NavLink
//           end={index === 0}
//           key={item.name}
//           to={item.to}
//           className={clsx(
//             'text-gray-300 hover:bg-gray-700 hover:text-white',
//             'group flex items-center px-2 py-2 text-base font-medium rounded-md'
//           )}
//         >
//           <item.icon
//             className={clsx(
//               'text-gray-400 group-hover:text-gray-300',
//               'mr-4 flex-shrink-0 h-6 w-6'
//             )}
//             aria-hidden="true"
//           />
//           {item.name}
//         </NavLink>
//       ))}
//     </>
//   );
// };

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { t } = useTranslation();
  const { logout, user } = useAuth();

  const userNavigation = [
    { name: t('COMMON.PROFILE'), to: '/profile?active=1' },
    {
      name: t('COMMON.SIGN_OUT'),
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <>
      <Menu as="div" className="ml-3 relative">
        {() => (
          <>
            <Menu.Button className="focus:outline-none">
              <Link
                to={user ? './favorite' : '/auth/login'}
                className="max-w-xs p-2 flex items-center flex-col text-sm focus:outline-none"
              >
                <img className="w-6 h-6 max-w-none" src={favorite} alt="" />
                {<div className="hidden md:block">お気に入り</div>}
              </Link>
            </Menu.Button>
          </>
        )}
      </Menu>

      <Menu as="div" className="ml-3 relative">
        {({ open }) => (
          <>
            <Menu.Button className="focus:outline-none">
              {user ? (
                <div className="max-w-xs p-2 flex items-center text-sm flex-col focus:outline-none">
                  <img className="w-6 h-6 max-w-none" src={profile} alt="" />
                  <div className="hidden md:block truncate max-w-[50px]">
                    {user.handle_name
                      ? user.handle_name
                      : user.first_name_kanji && user.last_name_kanji
                      ? `${user.last_name_kanji} ${user.first_name_kanji}`
                      : user.first_name_hiragana && user.last_name_hiragana
                      ? `${user.last_name_hiragana} ${user.first_name_hiragana}`
                      : user.email}
                  </div>
                </div>
              ) : (
                <Link
                  to={'/auth/login'}
                  className="max-w-xs p-2 flex items-center text-sm flex-col focus:outline-none"
                >
                  <img className="w-6 h-6 max-w-none" src={login} alt="" />
                  <div className="hidden md:block">ログイン</div>
                </Link>
              )}
            </Menu.Button>

            {user && (
              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          onClick={item.onClick}
                          to={item.to}
                          className={clsx(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            )}
          </>
        )}
      </Menu>
    </>
  );
};

// type MobileSidebarProps = {
//   sidebarOpen: boolean;
//   setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
// };

// const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
//   return (
//     <Transition.Root show={sidebarOpen} as={React.Fragment}>
//       <Dialog
//         as="div"
//         static
//         className="fixed inset-0 flex z-40 md:hidden"
//         open={sidebarOpen}
//         onClose={setSidebarOpen}
//       >
//         <Transition.Child
//           as={React.Fragment}
//           enter="transition-opacity ease-linear duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="transition-opacity ease-linear duration-300"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
//         </Transition.Child>
//         <Transition.Child
//           as={React.Fragment}
//           enter="transition ease-in-out duration-300 transform"
//           enterFrom="-translate-x-full"
//           enterTo="translate-x-0"
//           leave="transition ease-in-out duration-300 transform"
//           leaveFrom="translate-x-0"
//           leaveTo="-translate-x-full"
//         >
//           <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
//             <Transition.Child
//               as={React.Fragment}
//               enter="ease-in-out duration-300"
//               enterFrom="opacity-0"
//               enterTo="opacity-100"
//               leave="ease-in-out duration-300"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <div className="absolute top-0 right-0 -mr-12 pt-2">
//                 <button
//                   className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
//                   onClick={() => setSidebarOpen(false)}
//                 >
//                   <span className="sr-only">Close sidebar</span>
//                   <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
//                 </button>
//               </div>
//             </Transition.Child>
//             <div className="flex-shrink-0 flex items-center px-4">
//               <Logo />
//             </div>
//             <div className="mt-5 flex-1 h-0 overflow-y-auto">
//               <nav className="px-2 space-y-1">
//                 <SideNavigation />
//               </nav>
//             </div>
//           </div>
//         </Transition.Child>
//         <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
//       </Dialog>
//     </Transition.Root>
//   );
// };

// const Sidebar = () => {
//   return (
//     <div className="hidden md:flex md:flex-shrink-0">
//       <div className="flex flex-col w-64">
//         <div className="flex flex-col h-0 flex-1">
//           <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
//             <Logo />
//           </div>
//           <div className="flex-1 flex flex-col overflow-y-auto">
//             <nav className="flex-1 px-2 py-4 bg-gray-800 space-y-1">
//               <SideNavigation />
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const Logo = () => {
  return (
    <Link className="flex items-center text-white" to="/">
      <img className="h-12 w-auto" src={logo} alt="Workflow" />
      {/* <span className="text-xl text-white font-semibold">Travel With Dog</span> */}
    </Link>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
  authLayout?: boolean;
  showInstagram?: boolean;
};

export const MainLayout = ({ children, authLayout, showInstagram }: MainLayoutProps) => {
  // const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      {/* <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
      {/* <Sidebar /> */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden ">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow px-0 md:px-20 lg:px-40 py-1 container_header">
          {/* <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button> */}
          <div className="flex items-center flex-shrink-0 bg-white">
            <Logo />
          </div>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              {!authLayout && <UserNavigation />}
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {!authLayout && <Breadcrumb />}
          {children}
          <FooterGroup showInstagram={showInstagram} />
        </main>
      </div>
    </div>
  );
};
