import { Checkbox } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { FieldWrapper } from '@/components/Form/FieldWrapper';

import { useActivateAccount } from '../api/activate';
import { getTokenInfo } from '../api/getTokenInfo';
import { ActivateCredentialsDTO } from '../types';
type RegisterFormProps = {
  onSuccess: () => void;
};
export const ActivateForm = ({ onSuccess }: RegisterFormProps) => {
  const { mutateAsync, isLoading, isSuccess } = useActivateAccount();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [verified, setVerified] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const schema = z
    .object({
      first_name_kanji: z.string().min(1, t('COMMON.REQUIRED')),
      first_name_hiragana: z.string().min(1, t('COMMON.REQUIRED')),
      last_name_kanji: z.string().min(1, t('COMMON.REQUIRED')),
      last_name_hiragana: z.string().min(1, t('COMMON.REQUIRED')),
      password: z.string().min(8, t('COMMON.PASSWORD.REQUIRED')),
      RepeatPassword: z.string().min(8, t('COMMON.PASSWORD.REQUIRED')),
    })
    .refine((data) => data.password === data.RepeatPassword, {
      message: t('COMMON.PASSWORD_CONFIRM_ERROR'),
      path: ['RepeatPassword'],
    });
  useEffect(() => {
    if (token) {
      getInfo(token);
    }
  }, [token]);
  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const getInfo = async (token: string) => {
    const response = await getTokenInfo({
      type: 'sign_up',
      token: token,
    });
    const { email } = response;
    setEmail(email);
  };
  return (
    <div>
      <Form<ActivateCredentialsDTO, typeof schema>
        onSubmit={async (values) => {
          await mutateAsync({ ...values, token: token });
        }}
        schema={schema}
        id="activate"
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <FieldWrapper label={'お名前'}>
              <div className="flex space-x-2">
                <div className="flex-1">
                  <InputField
                    type="text"
                    placeholder={t('COMMON.FIRST_NAME')}
                    error={formState.errors['first_name_kanji']}
                    registration={register('first_name_kanji')}
                  />
                </div>
                <div className="flex-1">
                  <InputField
                    type="text"
                    placeholder={t('COMMON.LAST_NAME')}
                    error={formState.errors['last_name_kanji']}
                    registration={register('last_name_kanji')}
                  />
                </div>
              </div>
            </FieldWrapper>
            <div className="flex space-x-2">
              <div className="flex-1">
                <InputField
                  type="text"
                  placeholder={t('COMMON.FIRST_NAME_HIRAGANA')}
                  error={formState.errors['first_name_hiragana']}
                  registration={register('first_name_hiragana')}
                />
              </div>
              <div className="flex-1">
                <InputField
                  type="text"
                  placeholder={t('COMMON.LAST_NAME_HIRAGANA')}
                  error={formState.errors['last_name_hiragana']}
                  registration={register('last_name_hiragana')}
                />
              </div>
            </div>
            <InputField
              type="email"
              value={email}
              disabled={true}
              placeholder={t('COMMON.EMAIL')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label={t('COMMON.PASSWORD')}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label={t('COMMON.CONFIRM_PASSWORD')}
              error={formState.errors['RepeatPassword']}
              registration={register('RepeatPassword')}
            />
            <Checkbox checked={verified} onChange={(e) => setVerified(e.target.checked)}>
              <span>
                登録をもって<span className="font-bold">プライバシーポリシー</span>
                に同意したものとします。
              </span>
            </Checkbox>
            <div>
              <Button
                disabled={!verified}
                isLoading={isLoading}
                type="submit"
                className="w-full"
                variant="base"
              >
                {t('COMMON.ACTIVATE')}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
