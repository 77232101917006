import { Route, Routes } from 'react-router-dom';

import { NotFound } from '@/features/misc';

import { Activate } from './Activate';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { Register } from './Register';
import { RequestForgotPassword } from './RequestForgotPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="register" element={<Register />} />
      <Route path="fogotpassword" element={<RequestForgotPassword />} />
      <Route path="forgotpass" element={<ForgotPassword />} />
      <Route path="activate" element={<Activate />} />
      <Route path="login" element={<Login />} />
    </Routes>
  );
};
