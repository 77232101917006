import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

type RegisterValues = {
  email: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const { register, isRegistering } = useAuth();
  const { t } = useTranslation();

  const schema = z.object({
    email: z.string().min(1, t('COMMON.REQUIRED')),
  });
  return (
    <div>
      <Form<RegisterValues, typeof schema>
        onSubmit={async (values) => {
          await register(values);
          onSuccess();
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label={t('COMMON.EMAIL')}
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full bg-red-100">
                {t('COMMON.SIGN_UP')}
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <span>
            {t('COMMON.ACCOUNT.HAVE')}
            <span className="pl-2">
              <Link to="../login" className="font-medium text-red-100 hover:text-red-100">
                {t('COMMON.SIGN_IN')}
              </Link>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
