import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActivateForm } from '../components/ActivateForm';
import { Layout } from '../components/Layout';

export const Activate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Layout title={t('COMMON.SIGN_UP')}>
      <ActivateForm onSuccess={() => navigate('/auth/login')} />
    </Layout>
  );
};
